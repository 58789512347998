<template>
  <div class="about-components-index-wrapper">
    <div
      class="about-index-introduction"
    >
      <div class="about-index-introduction-title">{{ $t('aboutIntroductionTitle') }}</div>
      <div class="about-index-introduction-des">
        <p>{{ $t('aboutIntroductionDes[0]') }}</p>
        <p>{{ $t('aboutIntroductionDes[1]') }}</p>
        <p>{{ $t('aboutIntroductionDes[2]') }}</p>
      </div>
      <div class="about-index-introduction-value">
        <div class="left">
          <div class="img">
            <img v-if="$i18n.locale === 'zh'" src="@/assets/image/about/value.png" >
            <img v-else src="@/assets/image/about/value-en.png" >
          </div>
          <div class="list">
            <div
              v-for="(item, index) in $t('aboutIntroductionValue')"
              :key="item"
              :class="valueActive === index ? 'active' : ''"
              @click="valueActive = index"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div class="right">
          <div>
            {{ $t('aboutIntroductionValueDes')[valueActive] }} 
          </div>
        </div>
      </div>
    </div>
    <div
      ref="aboutDevelopment"
      class="about-index-development"
    >
      <div
        class="img"
      >
        <div>
          <img v-if="$i18n.locale === 'zh'" src="@/assets/image/about/milestones.png" />
          <img v-else src="@/assets/image/about/milestones-en.png" />
        </div>
        
      </div>
      <div class="content">
        <div class="year">
          <div 
            v-for="(item, index) in developmentList"
            :key="index"
            :class="developmentSwiperActive === (index + 1) ? 'active' : ''"
            @click="slideToLoop(index)"
          >
            {{ item.year }}
          </div>
        </div>
        <div class="content-swiper-scrollbar-wrapper">
          <div class="content-swiper-scrollbar"></div>
        </div>
        <swiper
          ref="mySwiper"
          :options="developmentSwiperOptions"
          @slideChangeTransitionStart="slideChangeTransitionEnd"
          @ready="swiperLoad"
        >
           <template
            v-for="(item, index) in developmentList"
          >
            <swiper-slide
              :key="index"
              class="development-slide"
            > 
              <div
                 class="development-slide-img"
              >
                <img
                  :src="item.image"
                />
              </div>
              <div
                class="development-slide-des"
              >
                <p
                >
                  {{ $t(item.prop) }}
                </p>
              </div>
            </swiper-slide>
          </template>
        </swiper>
      </div>
    </div>
    <div
      ref="aboutRwards"
      class="about-index-awards"
    >
      <div class="title">
        {{ $t('awards') }}
      </div>
      <div class="about-index-awards-swiper">
        <div class="awards-swiper-navigation">
          <div class="awards-swiper-button-prev">
            <img src="@/assets/image/about/navigation.png" />
          </div>
          <div class="awards-swiper-button-next">
            <img src="@/assets/image/about/navigation.png" />
          </div>
        </div>
        <swiper
          :options="awardsSwiperOptions"
        >
           <template
            v-for="(item, index) in awardsList"
          >
            <swiper-slide
              :key="index"
              class="awards-slide"
            > 
              <div
                 class="awards-slide-img"
              >
                <img
                  :src="item.imgage"
                />
              </div>
              <div
                class="awards-slide-des"
              >
                <div class="awards-slide-des-title">
                  {{ $t(item.prop)[index].title }}
                </div>
                <div class="awards-slide-des-des">
                  {{ $t(item.prop)[index].des }}
                </div>
              </div>
            </swiper-slide>
          </template>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { configAbout } from '@/utils/config'
export default {
  name: 'AboutComponentsIndex',
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      valueActive: 0,
      developmentList: configAbout.developmentList,
      swiper: null,
      developmentSwiperOptions: {
        scrollbar: {
          el: ".content-swiper-scrollbar",
          dragSize: 65,
        }
      },
      developmentSwiperActive: 1,
      awardsSwiperOptions: {
        loop: true,
        slidesPerView: 2,
        spaceBetween: 20,
        navigation: {
          nextEl: '.awards-swiper-button-next',
          prevEl: '.awards-swiper-button-prev',
        }
      },
      awardsList: configAbout.awardsList
    }
  },
  mounted() {
    this.scrollto()
  },
  beforeRouteUpdate(to, from, next) {
    next()
    if (to.query.scrollto) this.scrollto(to.query.scrollto)
  },
  methods: {
    swiperLoad() {
      this.swiper = this.$refs.mySwiper.$swiper
      this.developmentSwiperActive = this.getActiveIndex()
    },
    getActiveIndex() {
      return this.swiper.realIndex + 1
    },
    slideChangeTransitionEnd() {
      this.developmentSwiperActive = this.getActiveIndex()
    },
    slideToLoop(index) {
      this.swiper.slideToLoop(index, 300)
    },
    scrollto(to) {
      const scrollto = to || this.$route.query.scrollto
      if (scrollto && this.$refs[scrollto]) {
        this.$nextTick(() => {
          document.documentElement.scrollTop = this.$refs[scrollto].offsetTop - 70
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.about-components-index-wrapper {
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  .about-index-introduction {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 60px;
    .about-index-introduction-title {
      color: rgba(34, 34, 34, 1);
      font-size: 42px;
      line-height: 54px;
      text-align: center;
      font-weight: bold;
    }
    .about-index-introduction-des {
      color: rgba(51, 51, 51, 1);
      font-size: 18px;
      line-height: 32px;
      margin: 50px 0;
      p {
        margin: 40px 0;
      }
    }
    .about-index-introduction-value {
      width: 100%;
      height: 312px;
      background: url('~@/assets/image/about/core-value.png') rgba(245, 246, 247, 1) no-repeat right bottom;
      margin-bottom: 80px;
      margin-top: 100px;
      display: flex;
      align-items: center;
      padding: 50px;
      box-sizing: border-box;
      .left{
        flex: 1.114;
        border-right: 1px solid rgba(213, 213, 213, 1);
        height: 100%;
        flex-shrink: 0;
        .img {
          padding: 50px;
        }
        .list {
          display: flex;
          margin-left: 20px;
          margin-right: 50px;
          div {
            margin-left: 30px;
            cursor: pointer;
            font-size: 16px;
            color: rgba(136, 136, 136, 1);
            transition: all 0.3s;
            padding-bottom: 17px;
            line-height: 24px;
            font-weight: bold;
            &.active, &:hover {
              transition: all 0.3s;
              color: rgba(34, 34, 34, 1);
              border-bottom: 2px solid rgba(34, 34, 34, 1);
            }
          }
        }
      }
      .right {
        flex: 1;
        flex-shrink: 1;
        font-size: 18px;
        color: rgba(51, 51, 51, 1);
        line-height: 32px;
        height: 100%;
        padding-top: 15px;
        box-sizing: border-box;
        &>div {
          padding-left: 50px;
        }
      }
    }
  }
  .about-index-development {
    background: rgba(245, 246, 247, 1);
    overflow: hidden;
    .img{
     width: 100%;
     height: 400px;
     background: url('~@/assets/image/about/development.jpg') no-repeat center center;
    margin: 0 auto;
     &>div {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        height: 400px;
     }
    }
    .content {
      max-width: 1200px;
      margin: 0 auto;
      margin-bottom: 100px;
      margin-top: 70px;
      .year {
        display: flex;
        justify-content: space-between;
        color: rgba(153, 153, 153, 1);
        font-size: 20px;
        padding: 0 40px;
        cursor: pointer;
        & > div {
          transition: all 0.3s;
          line-height: 30px;
          width: 65px;
          text-align: center;
          &.active, &:hover {
            color: rgba(51, 51, 51, 1);
            font-size: 24px;
            font-weight: bold;
            transition: all 0.3s;
          }
        }
      }
      .content-swiper-scrollbar-wrapper {
        height: 2px;
        margin: 40px auto;
        background: rgba(214, 214, 214, 1);
        width: 100%;
        overflow: hidden;
        padding: 0 37.5px;
        .content-swiper-scrollbar {
          box-sizing: border-box;
          height: 2px;
          ::v-deep .swiper-scrollbar-drag {
            background: rgba(51, 51, 51, 1);
          }
        }
      }
      ::v-deep .development-slide {
        display: flex;
        height: 570px;
        background: #fff;
        width: 100%;
        .development-slide-img {
          flex: 1.18;
          display: flex;
          justify-content: center;
        }
        .development-slide-des {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          p {
            color: rgba(51, 51, 51, 1);
            font-size: 18px;
            font-weight: bold;
            line-height: 32px;
            margin: 15px 60px;
          }
        }
      }
    }
  }
  .about-index-awards {
    .title {
      font-size: 42px;
      color: rgba(34, 34, 34, 1);
      font-weight: bold;
      text-align: center;
      padding: 80px 0 60px 0;
    }
    .about-index-awards-swiper {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
      margin-bottom: 133px;
      background: rgba(0, 0, 0, 0.05);
      position: relative;
      display: flex;
      justify-content: center;
      ::v-deep .awards-slide {
        overflow: hidden;
        // box-shadow: 0px 0 20px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #fff;
        .awards-slide-img {
          margin-top: 20px;
          margin-bottom: 20px;
          width: 56%;
          display: flex;
          justify-content: center;
          align-items: center;  
        }
        .awards-slide-des {
          margin: 20px 0;
          padding: 0px 40px;
          box-sizing: border-box;
          .awards-slide-des-title {
            color: rgba(51, 51, 51, 1);
            font-size: 26px;
            line-height: 32px;
            text-align: left;
            font-weight: bold;
          }
          .awards-slide-des-des {
            font-size: 18px;
            color: rgba(51, 51, 51, 1);
            line-height: 32px;
            margin-top: 32px;
            min-height: 200px;
            overflow: hidden;
            margin-bottom: 10px;
          }
        }
      }
      .awards-swiper-navigation {
        width: calc(100% + 100px); 
        display: flex;
        justify-content: space-between;
        height: 25px;
        position: absolute;
        z-index: 1;
        top: 50%;
        &>div {
          cursor: pointer;
          &.awards-swiper-button-next {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
</style>